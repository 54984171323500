<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-table hover small :items="resultTableValues" :fields="resultTableFields"></b-table>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-textarea
                            id="textarea"
                            v-model="jsonInput"
                            placeholder="Enter JSON..."
                            rows="3"
                            max-rows="5"
                            @input="touchJSONInput($event)"
                            :disabled="bulkIssueStarted || lockInput"
                        ></b-form-textarea>
                        <b-button-toolbar justify>
                            <b-button v-if="!bulkIssueProcessing && !lockInput" variant="danger" @click="clearJSONInput()">Clear JSON Input</b-button>
                            <b-button v-else-if="lockInput" variant="danger" @click="cancelInputProcessing()">{{(bulkIssueProcessing) ? "Cancel Procedure": "Unlock Input"}}</b-button>
                        </b-button-toolbar>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        Processing Request: {{onRequest}} of {{inpReqCount}}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
            
        <b-button-toolbar justify>
            <b-button variant="info"  @click="$router.go(-1)">Exit</b-button>
            <b-overlay :show="bulkIssueProcessing" rounded="sm">
                <b-button v-if="!bulkIssueProcessing" variant="success" :disabled="this.inputObjectArray.length == 0" @click="startBulkIssue()">Start Bulk Issue</b-button>
                <b-button v-else variant="success" disabled>Submitted</b-button>
            </b-overlay>
        </b-button-toolbar>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

export default {
    name: 'fulcrumbulkissue',
    props: [],
    components: {
    },
    data(){
        return{
            hasError: false,
            hasErrorValue: '',
            fetchingDevice: true,
            loading_message: 'Instantiating Instantiation Engine',
            required_requests: 1,
            completed_requests: 0,
            bulkIssueStarted: false,
            bulkIssueProcessing: false,
            jsonInput: null,
            inputObjectArray: [],
            lockInput: false,
            cancelTriggered: false,
            output: null,
            inpReqCount: 0,
            onRequest: 1,
            index: 0,
            resultTableValues: [],
            resultTableFields: ["device_id","process_status","result"]
        }
    },
    methods: {
        touchJSONInput(){
            this.parseJSONInput();
        },
        clearJSONInput(){
            this.inputObjectArray = [];
            this.jsonInput = null;
            this.lockInput = false;
            this.inpReqCount = this.inputObjectArray.length;
            this.resultTableValues = [];
        },
        parseJSONInput(){
            this.inputObjectArray = [];
            var arr = JSON.parse(this.jsonInput);
            this.inputObjectArray = arr;
            console.log("Bulk JSON Issue - Input Value: ")
            console.log(this.inputObjectArray);
            this.inpReqCount = this.inputObjectArray.length;
            this.lockInput = true;
            // Fill Input Table
            this.resultTableValues = [];
            this.inputObjectArray.forEach((val)=>{
                this.resultTableValues.push({device_id: val.deviceID, form_id: val.formID, process_status: "In Queue", result: "N/A", _rowVariant: 'secondary'})
            })
        },
        startBulkIssue(){
            this.cancelTriggered = false;
            this.bulkIssueStarted = true;
            this.bulkIssueProcessing = true;
            this.lockInput = true;
            console.log("Button Stub")
            this.parseJSONInput();
            if(this.inputObjectArray.length == 0){
                this.lockInput = false;
                this.bulkIssueStarted = false;
                this.bulkIssueProcessing = false;
                return;
            }
            this.index = 0;
            this.onRequest = 1;

            // We can proceed
            // Process Sequentially via Callback
            this.sequential(null, null, null, null);
            
        },
        sequential(prevDeviceID, prevFormID, prevResultBool, prevResultValue){
                
            if(this.cancelTriggered){
                // Fill the values for the most recent item
                var matches = this.resultTableValues.filter((row)=>{ return row.device_id == prevDeviceID && row.form_id == prevFormID; })
                if(matches.length == 0){
                    console.log(`No Matching Table Record For DeviceID: ${prevDeviceID} and formID: ${prevFormID}`);
                }else{
                    if(prevResultBool){
                        // Success
                        matches[0]._rowVariant = 'success';
                        matches[0].result = prevResultValue;
                        matches[0].process_status = "Completed";
                        this.inputObjectArray[this.index].completed = true;
                    }else{
                        // Failure
                        matches[0]._rowVariant = 'warning';
                        matches[0].result = prevResultValue;
                        matches[0].process_status = "Failed";
                        this.inputObjectArray[this.index].completed = false;
                    }
                }
                var denoteCanceledMatches = this.resultTableValues.filter((row)=>{ return row.process_status == "In Queue"; })
                denoteCanceledMatches.forEach((row)=>{
                    row._rowVariant = 'danger';
                    row.result = 'Canceled By User';
                    row.process_status = 'Canceled';
                })
                // This has been canceled, do nothing more
                console.log(`Processing Interupted By Cancelation at index ${this.index}`);
                this.bulkIssueStarted = false;
                this.bulkIssueProcessing = false;
                return;
            }else if(prevResultBool == null){
                // This is the first item
                console.log(`Starting Sequential Processing...`);
                var matches = this.resultTableValues.filter((row)=>{ return row.device_id == this.inputObjectArray[this.index].deviceID && row.form_id == this.inputObjectArray[this.index].formID; })
                if(matches.length == 0){
                    console.log(`No Matching Table Record For DeviceID: ${this.inputObjectArray[this.index].deviceID} and formID: ${this.inputObjectArray[this.index].formID}`);
                }else{
                    matches[0]._rowVariant = 'info';
                    matches[0].process_status = "Processing";
                }
                this.processSingle(this.inputObjectArray[this.index].deviceID, this.inputObjectArray[this.index].formID, this.inputObjectArray[this.index].status, this.inputObjectArray[this.index].selectJobTasks, this.inputObjectArray[this.index].productCode, this.sequential);
            }else{
                console.log(`COMPLETED - Index: ${this.index} DeviceID: ${prevDeviceID}`);
                // Update the table with the info from the previous run
                var matches = this.resultTableValues.filter((row)=>{ return row.device_id == prevDeviceID && row.form_id == prevFormID; })
                if(matches.length == 0){
                    console.log(`No Matching Table Record For DeviceID: ${prevDeviceID} and formID: ${prevFormID}`);
                }else{
                    if(prevResultBool){
                        // Success
                        matches[0]._rowVariant = 'success';
                        matches[0].result = prevResultValue;
                        matches[0].process_status = "Completed";
                        this.inputObjectArray[this.index].completed = true;
                    }else{
                        // Failure
                        matches[0]._rowVariant = 'warning';
                        matches[0].result = prevResultValue;
                        matches[0].process_status = "Failed";
                        this.inputObjectArray[this.index].completed = false;
                    }
                }
                // Push the index ahead if possible
                if(this.index+1 < this.inputObjectArray.length){
                    this.index++;
                    this.onRequest++;
                    // Call Generator for next item
                    console.log(`START - Index: ${this.index} DeviceID: ${this.inputObjectArray[this.index].deviceID}`);
                    var matches = this.resultTableValues.filter((row)=>{ return row.device_id == this.inputObjectArray[this.index].deviceID && row.form_id == this.inputObjectArray[this.index].formID; })
                    if(matches.length == 0){
                        console.log(`No Matching Table Record For DeviceID: ${this.inputObjectArray[this.index].deviceID} and formID: ${this.inputObjectArray[this.index].formID}`);
                    }else{
                        matches[0]._rowVariant = 'info';
                        matches[0].process_status = "Processing";
                    }
                    this.processSingle(this.inputObjectArray[this.index].deviceID, this.inputObjectArray[this.index].formID, this.inputObjectArray[this.index].status, this.inputObjectArray[this.index].selectJobTasks, this.inputObjectArray[this.index].productCode, this.sequential);
                }else{
                    // End of the array i think
                    console.log(`All Items Processed`);
                    this.bulkIssueStarted = false;
                    this.bulkIssueProcessing = false;
                }
            }
        },
        processSingle(deviceID, formID, status, selectJobTasks, productCode, callback){
            var issueRequestPacket = {
                deviceID: deviceID,
                formID: formID,
                status: status,
                selectJobTasks: selectJobTasks,
                productCode: productCode,
            };

            instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/fulcrum/issue.json", issueRequestPacket)
            .then((response)=>{
                callback(deviceID, formID, true, response.data.result.fulcrumRecordURL);
            })
            .catch((err)=>{
                // TODO: An Error Has OCcured Do Something Useful With it
                console.log(`Issue Error For DeviceID: ${deviceID}`)
                console.log(err)
                callback(deviceID, formID, false, "Error In Console");
            })
        },
        cancelInputProcessing(){
            if(this.bulkIssueProcessing){
                this.cancelTriggered = true;
            }else{
                this.lockInput = false;
                this.bulkIssueStarted = false;
                this.bulkIssueProcessing = false;
            }
        }
        
    },
    watch: {
        completed_requests: function (newVal, oldVal){
            var messages = [
                'Instantiating Instantiation Engine', 
                'Aligning Covariance Matrices', 
                'Normalizing Data Metrics', 
                'Resolving Quantum Irregularites', 
                'Pushing Pixels Around', 
                'Complete'
            ];
            this.loading_message = messages[newVal];
            console.log("Completed: " + newVal + "/" + this.required_requests);
            if(newVal == this.required_requests){
                this.fetchingDevice = false;
            }
        }
    },
    mounted (){
        this.completed_requests = 0;
    },
}
</script>

<style scoped>
.tab-in{
    margin-left: 1em;
}
.btn-small-separation{
    margin-bottom: 0.25em;
}
</style>
